import React from 'react'

function Hero() {
    return (
        <div className='hero'>
            <div className="background-image"></div>
            <div className='hf'>
                <h1 >Welcome to Gireesh Enterprises</h1>
                <p>Connecting Talent To Oppotunity</p>
                
                </div>

            
        </div>
    );
}

export default Hero;
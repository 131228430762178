import React from "react";

function Footer() {
    return (
        <footer>
            <p>&copy; 2023 Gireesh's Construction Staffing Agency</p>
            <a href="#">Privacy Policy</a>
            <a href="#">Terms of Service</a>
        </footer>
    );
}

export default Footer;